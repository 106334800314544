import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" 
          transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
       <path d="M2366 4719 c-71 -17 -172 -78 -234 -143 -100 -104 -147 -221 -187
-465 l-17 -104 56 -55 c30 -31 57 -54 59 -52 2 3 34 96 71 207 74 223 122 309
208 372 93 69 212 73 302 12 49 -34 131 -143 220 -291 31 -52 62 -100 69 -106
11 -11 217 -28 217 -19 0 9 -133 230 -199 330 -165 253 -361 362 -565 314z"/>
<path d="M1695 4014 c-224 -137 -336 -220 -384 -285 l-23 -31 58 -59 58 -59
46 47 c43 44 203 155 322 222 53 29 57 35 62 74 11 91 15 177 8 177 -4 0 -70
-39 -147 -86z"/>
<path d="M2397 3576 c-45 -16 -64 -32 -72 -56 -5 -15 17 -43 90 -115 84 -85
98 -94 116 -85 54 29 77 132 42 192 -35 58 -112 87 -176 64z"/>
<path d="M3631 3556 l-74 -32 -1 -90 c-1 -136 -34 -183 -214 -302 -48 -32
-120 -77 -159 -101 -53 -31 -73 -49 -74 -65 0 -11 -4 -60 -7 -108 -4 -49 -6
-88 -5 -88 5 0 182 106 273 163 116 73 240 173 283 230 83 109 116 256 83 370
-9 31 -20 57 -24 56 -4 0 -40 -15 -81 -33z"/>
<path d="M2026 2959 c43 -44 88 -82 99 -84 31 -7 281 -34 370 -40 l80 -5 -180
61 c-99 33 -240 80 -314 104 l-135 44 80 -80z"/>
<path d="M1835 2754 c71 -122 187 -304 227 -356 l44 -57 37 87 c20 48 37 91
37 95 0 6 -116 205 -147 254 -5 6 -53 14 -117 18 l-109 7 28 -48z"/>
<path d="M4012 1619 c-36 -14 -25 -50 21 -72 39 -19 48 -43 21 -54 -8 -3 -26
-1 -39 5 -13 6 -27 8 -29 3 -6 -9 36 -31 58 -31 8 0 24 8 36 18 29 23 18 48
-31 71 -47 22 -45 47 4 44 20 -1 37 2 37 7 0 6 -6 10 -14 10 -8 0 -20 2 -28 4
-7 3 -23 1 -36 -5z"/>
<path d="M680 1544 l0 -77 46 6 c56 6 87 34 87 76 0 48 -28 71 -85 71 l-48 0
0 -76z m100 46 c11 -11 20 -29 20 -40 0 -29 -36 -60 -70 -60 l-30 0 0 60 0 60
30 0 c17 0 39 -9 50 -20z"/>
<path d="M940 1545 l0 -75 55 0 c30 0 55 5 55 10 0 6 -20 10 -45 10 -41 0 -45
2 -45 25 0 23 4 25 40 25 22 0 40 5 40 10 0 6 -18 10 -40 10 -36 0 -40 3 -40
24 0 22 5 25 43 29 40 3 40 4 -10 5 l-53 2 0 -75z"/>
<path d="M1180 1544 l0 -77 46 6 c56 6 87 34 87 76 0 48 -28 71 -85 71 l-48 0
0 -76z m100 46 c11 -11 20 -29 20 -40 0 -29 -36 -60 -70 -60 l-30 0 0 60 0 60
30 0 c17 0 39 -9 50 -20z"/>
<path d="M1450 1600 c-25 -25 -26 -81 -2 -103 10 -9 32 -20 50 -23 26 -5 36
-1 63 25 25 26 30 37 24 59 -3 15 -14 35 -22 45 -23 24 -87 23 -113 -3z m100
-10 c11 -11 20 -29 20 -40 0 -26 -34 -60 -62 -60 -50 0 -76 63 -41 102 21 24
58 23 83 -2z"/>
<path d="M1710 1545 c0 -88 18 -102 22 -17 l3 57 44 -57 c24 -32 47 -58 52
-58 5 0 9 34 9 75 0 87 -18 102 -22 19 l-3 -56 -40 56 c-55 76 -65 73 -65 -19z"/>
<path d="M2120 1544 l0 -77 50 5 c64 6 90 29 90 78 0 51 -24 70 -88 70 l-52 0
0 -76z m102 49 c22 -20 24 -66 2 -87 -8 -9 -31 -16 -50 -16 l-34 0 0 60 0 60
32 0 c17 0 40 -7 50 -17z"/>
<path d="M2405 1558 c-39 -89 -38 -88 -27 -88 6 0 14 9 17 20 5 16 15 20 50
20 35 0 45 -4 50 -20 3 -11 11 -20 16 -20 12 0 6 23 -28 96 -13 30 -30 54 -37
54 -8 0 -26 -28 -41 -62z m63 0 c11 -28 11 -28 -24 -28 -19 0 -33 4 -30 9 3 4
10 21 16 36 11 30 19 26 38 -17z"/>
<path d="M2643 1613 c26 -4 27 -6 27 -74 0 -39 4 -69 10 -69 6 0 10 30 10 69
0 68 1 70 28 74 15 2 -2 4 -38 4 -36 0 -53 -2 -37 -4z"/>
<path d="M2876 1548 c-33 -73 -37 -103 -9 -60 21 31 98 32 106 2 3 -11 10 -20
15 -20 6 0 -3 34 -21 75 -17 41 -37 75 -44 75 -8 0 -29 -33 -47 -72z m63 21
c6 -18 11 -34 11 -35 0 -2 -14 -4 -31 -4 -26 0 -30 3 -24 18 4 9 10 25 12 35
8 26 20 21 32 -14z"/>
<path d="M3254 1603 c36 -97 52 -133 61 -133 5 0 22 30 38 68 29 72 31 82 19
82 -4 0 -16 -25 -27 -56 -10 -30 -22 -57 -26 -60 -4 -2 -18 23 -30 56 -12 33
-26 60 -31 60 -6 0 -7 -8 -4 -17z"/>
<path d="M3510 1545 l0 -75 55 0 c30 0 55 5 55 10 0 6 -20 10 -45 10 -41 0
-45 2 -45 25 0 22 4 25 35 25 19 0 35 5 35 10 0 6 -16 10 -35 10 -30 0 -35 3
-35 24 0 22 5 25 43 29 40 3 40 4 -10 5 l-53 2 0 -75z"/>
<path d="M3740 1545 c0 -43 4 -75 10 -75 6 0 10 11 10 25 0 21 5 25 30 25 23
0 33 -6 42 -25 6 -14 15 -25 20 -25 12 0 10 8 -7 34 -14 21 -14 24 0 29 21 9
20 73 -1 81 -9 3 -36 6 -60 6 l-44 0 0 -75z m101 49 c20 -24 -1 -48 -45 -52
-35 -3 -36 -2 -36 32 0 34 2 36 34 36 18 0 39 -7 47 -16z"/>
<path d="M4220 1545 l0 -75 55 0 c30 0 55 5 55 10 0 6 -20 10 -45 10 -41 0
-45 2 -45 25 0 23 4 25 40 25 22 0 40 5 40 10 0 6 -18 10 -40 10 -36 0 -40 3
-40 24 0 22 5 25 48 29 46 3 46 3 -10 5 l-58 2 0 -75z"/>
<path d="M1303 1210 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M1184 1255 c-3 -8 -4 -20 -1 -27 3 -7 6 -3 6 9 1 12 7 24 14 26 9 4
9 6 -1 6 -7 1 -15 -6 -18 -14z"/>
<path d="M1962 1203 c0 -38 3 -54 5 -36 4 27 9 32 36 36 l32 4 -34 1 c-31 2
-33 4 -29 32 3 17 1 30 -4 30 -4 0 -7 -30 -6 -67z"/>
<path d="M2662 1208 l-2 -68 33 1 c29 2 30 2 5 6 -26 4 -27 8 -31 66 l-3 62
-2 -67z"/>
<path d="M2720 1263 c14 -7 13 -43 -2 -43 -6 0 -4 -6 7 -14 12 -8 16 -20 12
-35 -3 -11 -1 -19 4 -15 11 6 12 38 2 48 -4 4 -7 19 -8 33 0 14 -6 27 -13 29
-7 1 -8 0 -2 -3z"/>
<path d="M3132 1208 l-2 -68 33 1 c29 2 30 2 5 6 -26 4 -27 8 -31 66 l-3 62
-2 -67z"/>
<path d="M3186 1263 c6 -4 14 -15 17 -23 5 -11 6 -9 4 4 -1 11 -9 21 -17 23
-10 3 -11 2 -4 -4z"/>
<path d="M3412 1208 l-2 -68 33 1 c29 2 30 2 5 6 -26 4 -27 8 -31 66 l-3 62
-2 -67z"/>
<path d="M3470 1263 c14 -7 13 -43 -2 -43 -6 0 -4 -6 7 -14 12 -8 16 -20 12
-35 -3 -11 -1 -19 4 -15 11 6 12 38 2 48 -4 4 -7 19 -8 33 0 14 -6 27 -13 29
-7 1 -8 0 -2 -3z"/>
<path d="M2182 1244 c-7 -8 -8 -14 -3 -14 5 0 11 -19 13 -42 2 -29 8 -43 18
-44 9 0 10 1 3 3 -8 3 -13 21 -13 44 0 30 4 39 18 40 13 0 14 2 2 6 -8 3 -17
9 -20 13 -3 4 -11 2 -18 -6z"/>
<path d="M2892 1244 c-7 -8 -8 -14 -3 -14 5 0 11 -19 13 -42 2 -29 8 -43 18
-44 9 0 10 1 3 3 -8 3 -13 21 -13 44 0 30 4 39 18 40 13 0 14 2 2 6 -8 3 -17
9 -20 13 -3 4 -11 2 -18 -6z"/>
<path d="M3642 1244 c-7 -8 -8 -14 -3 -14 5 0 11 -19 13 -42 2 -29 8 -43 18
-44 9 0 10 1 3 3 -8 3 -13 21 -13 44 0 30 4 39 18 40 13 0 14 2 2 6 -8 3 -17
9 -20 13 -3 4 -11 2 -18 -6z"/>
<path d="M1845 1241 c-31 -6 -45 -35 -27 -53 6 -6 8 0 4 18 -4 24 -3 26 21 22
16 -3 28 0 31 8 3 8 4 13 3 13 -1 -1 -15 -4 -32 -8z"/>
<path d="M1330 1236 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M1448 1233 c18 -4 22 -11 22 -43 0 -21 4 -41 8 -44 4 -2 5 16 2 42
-4 41 -8 47 -30 49 -22 1 -22 1 -2 -4z"/>
<path d="M1523 1170 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M1565 1233 c11 -3 24 -10 28 -17 6 -7 7 -6 4 4 -2 8 -14 16 -28 17
-19 1 -20 1 -4 -4z"/>
<path d="M1643 1190 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M1690 1190 c0 -29 2 -51 4 -49 8 8 10 88 3 95 -4 4 -7 -17 -7 -46z"/>
<path d="M1737 1234 c18 -4 23 -14 26 -52 l4 -47 -1 50 c-1 46 -3 50 -26 52
-21 1 -21 1 -3 -3z"/>
<path d="M2133 1190 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M2323 1195 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M2408 1233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2462 1228 c-7 -7 -12 -24 -12 -38 0 -14 5 -31 12 -38 14 -14 44 -16
52 -4 2 4 -7 8 -22 7 -17 0 -28 6 -30 18 -3 14 4 17 32 17 23 0 36 5 36 13 0
18 -21 37 -40 37 -9 0 -21 -5 -28 -12z m56 -15 c2 -9 -7 -13 -27 -13 -30 0
-39 9 -24 24 11 10 46 3 51 -11z"/>
<path d="M2990 1236 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M3732 1228 c-7 -7 -12 -24 -12 -38 0 -14 5 -31 12 -38 14 -14 44 -16
52 -4 2 4 -7 8 -22 7 -17 0 -28 6 -30 18 -3 14 4 17 32 17 23 0 36 5 36 13 0
18 -21 37 -40 37 -9 0 -21 -5 -28 -12z m56 -15 c2 -9 -7 -13 -27 -13 -30 0
-39 9 -24 24 11 10 46 3 51 -11z"/>
<path d="M3250 1225 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M1236 1203 c7 -4 14 -19 17 -33 4 -22 5 -21 4 4 -1 17 -9 31 -17 33
-10 3 -11 2 -4 -4z"/>
<path d="M3205 1180 c0 -15 3 -26 8 -23 4 2 7 13 7 23 0 10 -3 21 -7 23 -5 3
-8 -8 -8 -23z"/>
<path d="M2988 1193 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M1414 1160 c1 -8 10 -16 21 -17 15 -1 15 0 3 3 -9 3 -19 11 -21 17
-2 7 -3 5 -3 -3z"/>
<path d="M3276 1153 c9 -36 10 -38 16 -16 3 10 -1 24 -9 32 -12 10 -13 8 -7
-16z"/>
<path d="M2263 1160 c2 -8 12 -16 23 -17 13 -2 15 -1 4 4 -8 3 -19 11 -23 17
-6 7 -7 6 -4 -4z"/>
<path d="M2979 1158 c14 -19 46 -24 55 -9 4 5 -3 7 -14 4 -11 -3 -28 1 -38 8
-15 13 -16 12 -3 -3z"/>
<path d="M1190 1150 c8 -5 24 -10 34 -10 26 0 18 6 -19 14 -22 5 -26 4 -15 -4z"/>
<path d="M2078 1148 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M2810 1150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3560 1150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1558 1143 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1801 1124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1845 1110 l-30 -8 27 -1 c15 0 30 4 33 9 3 6 5 9 3 9 -2 -1 -16 -5
-33 -9z"/>
<path d="M3248 1103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
